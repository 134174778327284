import React from "react";
import * as Chakra from "@chakra-ui/react";
import { PageProps } from "gatsby";
import { Seo } from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import Logo from "../images/logo.svg";
import { HeadingLarge } from "../components/heading-large";
import { darken } from "@chakra-ui/theme-tools";
import RendererState from "../components/pdf/renderer-state";
import FadeTransition from "../components/transitions/fade-transition";

const Finish = ({ location }: PageProps): React.ReactElement => {
  const isRendering = RendererState.useGlobalState((gs) => gs.isRendering);
  const renderInfo = RendererState.useGlobalState((gs) => gs.renderInfo);
  const emailAddress = RendererState.useGlobalState((gs) => gs.emailAddress);
  const hasRendered = RendererState.useGlobalState((gs) => gs.hasRendered);

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <Chakra.Box width="100%">
      <Seo title="Course Complete" location={location} />
      <Chakra.Flex
        width={{ base: "100vw", lg: "34vw" }}
        height="100vh"
        bgColor="#FFFFFF"
        position="fixed"
        top="0"
        left="0"
        zIndex="8"
        px="35px"
        alignItems="center"
      >
        <Chakra.Box maxWidth="436px" pt={{ base: "40px", lg: "0" }}>
          <HeadingLarge pb="35px">
            Course
            <br />
            Completed.
          </HeadingLarge>
          <Chakra.Text
            pb="35px"
            fontSize="25px"
            lineHeight="35px"
            letterSpacing="0.2px"
          >
            Thanks for completing the Experimentation Workbook.
          </Chakra.Text>
          <Chakra.Text
            fontSize="14px"
            lineHeight="20px"
            letterSpacing="0.11px"
            as="div"
            pb="30px"
            pt="10px"
          >
            <b>Get your results</b>
            <p>Enter your email to receive a PDF export.</p>
          </Chakra.Text>
          <Chakra.HStack
            spacing="14px"
            width="100%"
            as="form"
            onSubmit={(e) => e.preventDefault()}
          >
            <Chakra.Input
              isDisabled={isRendering}
              placeholder="me@example.com"
              borderColor="#BABABA"
              borderRadius="2px"
              height="40px"
              fontSize="14px"
              pl="16px"
              letterSpacing="0.11px"
              lineHeight="14px"
              value={emailAddress}
              onChange={(e) => RendererState.setEmail(e.target.value)}
            />
            <Chakra.Button
              backgroundColor="#243475"
              color="#fff"
              fontSize="14px"
              letterSpacing="0.11px"
              px="20px"
              fontWeight="400"
              borderRadius="0"
              transition="0.3s"
              width="105px"
              type="submit"
              isLoading={isRendering}
              isDisabled={
                !emailRegex.test(emailAddress) || isRendering || hasRendered
              }
              _hover={{
                backgroundColor: darken("#243475", 5),
              }}
              _focus={{
                backgroundColor: darken("#243475", 8),
              }}
              _active={{
                backgroundColor: darken("#243475", 8),
              }}
              onClick={RendererState.shouldRender}
            >
              {!isRendering && !hasRendered && "Send"}
              {!isRendering && hasRendered && "Sent!"}
            </Chakra.Button>
          </Chakra.HStack>

          <FadeTransition shouldChange={renderInfo}>
            <Chakra.Text
              pt="35px"
              fontSize="14px"
              lineHeight="20px"
              letterSpacing="0.11px"
            >
              {renderInfo}
            </Chakra.Text>
          </FadeTransition>
        </Chakra.Box>
      </Chakra.Flex>
      <Chakra.Box
        top={{ base: "50vh", lg: "0" }}
        d={{ base: "none", lg: "unset" }}
        backgroundColor="black"
        width={{ base: "100vw", lg: "66vw" }}
        height={{ base: "50vh", lg: "100vh" }}
        bgColor="#FFFFFF"
        position="fixed"
        right="0"
        zIndex="8"
      >
        <StaticImage
          src="../images/finish.png"
          alt="A kitten"
          style={{ width: "100%", height: "100%" }}
          placeholder="blurred"
        />
        <Chakra.Flex
          color="#fff"
          position="absolute"
          height="100%"
          width="100%"
          top="0"
          css={{ svg: { width: "472px", height: "242px" } }}
          alignItems="center"
          justifyContent="center"
        >
          <Logo />
        </Chakra.Flex>
      </Chakra.Box>
    </Chakra.Box>
  );
};

export default Finish;
